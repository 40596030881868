import { getLoyaltyProgram as getLoyaltyProgramRequest } from '@wix/ambassador-loyalty-v1-program/http';
import { LoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/types';
import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

export async function getLoyaltyProgram(flowAPI: PlatformControllerFlowAPI): Promise<LoyaltyProgram> {
  const { httpClient } = flowAPI;

  const response = await httpClient.request(getLoyaltyProgramRequest({}));

  return response.data.loyaltyProgram!;
}
