import { createBlocksModel, WidgetPropertyType } from '@wix/yoshi-flow-editor/blocks';

export default createBlocksModel({
  widgetName: 'LoyaltyCheckoutPlugin',
  props: {
    checkoutId: {
      type: WidgetPropertyType.STRING,
      title: 'Checkout object GUID identifier.',
    },
    stepId: {
      type: WidgetPropertyType.STRING,
      title: 'Current checkout step identifier.',
    },
    slotId: {
      type: WidgetPropertyType.STRING,
      title: 'Identifier of the current slot rendering the widget.',
    },
  },
  methods: {
    onRefreshCheckout: {
      description: 'Method used to inject checkout refresh function.',
      params: [
        {
          name: 'Checkout refresh function.',
        },
      ],
    },
  },
  events: {},
});
