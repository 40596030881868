import { FixedRewardSource } from './constants';
import { FixedReward } from './getValidFixedRewards';

// Returns all fixed rewards the user can redeem on this checkout.
export function getAvailableFixedRewards(validFixedRewards: FixedReward[], userPointsAmount: number): FixedReward[] {
  return validFixedRewards
    .filter((fixedReward) => {
      if (fixedReward.source === FixedRewardSource.LoyaltyReward) {
        return fixedReward.costInPoints <= userPointsAmount;
      }

      return true;
    })
    .sort((first, second) => {
      if (first.source === FixedRewardSource.LoyaltyReward && second.source !== FixedRewardSource.LoyaltyReward) {
        return 1;
      } else if (
        first.source !== FixedRewardSource.LoyaltyReward &&
        second.source === FixedRewardSource.LoyaltyReward
      ) {
        return -1;
      }

      return second.createdTime - first.createdTime;
    });
}
