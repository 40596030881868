export enum RewardDiscountType {
  Money = 'money',
  Percentage = 'percentage',
  FreeShipping = 'freeShipping',
}

export enum FixedRewardSource {
  LoyaltyReward = 'loyaltyReward',
  LoyaltyCoupon = 'loyaltyCoupon',
  ReferralReward = 'referralReward',
}

export enum SelectedRewardType {
  Flexible = 'flexible',
  Fixed = 'fixed',
}

export const FLEXIBLE_REWARD_COUPON_NAME = 'Loyalty - order discount';
export const ICON_BASE_URL = 'https://static.wixstatic.com';
