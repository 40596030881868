import { ElementId } from './constants';
import model from './model';

export default model.createController(({ $bind, $props, $widget, $w }) => {
  return {
    async pageReady() {
      $bind(`#${ElementId.RewardDropdownLabelText}`, {
        collapsed: () => !$props.dropdownLabel,
        text: () => $props.dropdownLabel,
      });

      $bind(`#${ElementId.RewardDropdown}`, {
        options: () => ($props as any).dropdownOptions,
        value: () => $props.dropdownValue,
        onChange(event) {
          $widget.fireEvent('dropdownChange', event.target.value);
        },
      });

      $bind(`#${ElementId.Error}`, {
        collapsed: () => !$props.errorMessage,
      });

      $bind(`#${ElementId.ErrorText}`, {
        text: () => $props.errorMessage,
      });

      $bind(`#${ElementId.StatusText}`, {
        collapsed: () => !$props.statusText,
        text: () => $props.statusText,
      });
    },
    exports: {},
  };
});
