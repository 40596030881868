export enum ElementId {
  MainStates = 'mainStates',
  LoadingState = 'loadingState',
  LoadedState = 'loadedState',
  StatusText = 'statusText',
  EarnPointsText = 'earnPointsText',
  ContentStates = 'contentStates',
  NotLoggedInState = 'notLoggedInState',
  NotEnoughPointsState = 'notEnoughPointsState',
  FlexibleRewardState = 'flexibleRewardState',
  FixedRewardState = 'fixedRewardState',
  BothRewardsState = 'bothRewardsState',
  CodeAppliedState = 'codeAppliedState',
  CodeAppliedText = 'codeAppliedText',
  LogInText = 'logInText',
  LogInButton = 'logInButton',
  FlexibleRewardWidget = 'flexibleRewardWidget',
  FlexibleRewardWidgetBoth = 'flexibleRewardWidgetBoth',
  FixedRewardWidget = 'fixedRewardWidget',
  FixedRewardWidgetBoth = 'fixedRewardWidgetBoth',
  NotEnoughPointsWidget = 'notEnoughPointsWidget',
  NotEnoughPointsWidgetFlexible = 'notEnoughPointsWidgetFlexible',
  NotEnoughPointsWidgetFixed = 'notEnoughPointsWidgetFixed',
  ApplyRewardButton = 'applyRewardButton',
  ProgramIcon = 'programIcon',
  FlexibleRewardRadioGroup = 'flexibleRewardRadioGroup',
  FixedRewardRadioGroup = 'fixedRewardRadioGroup',
  ChooseRewardText = 'chooseRewardText',
  RadioGroupSpacer = 'radioGroupSpacer',
}
