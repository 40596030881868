import { Checkout } from '@wix/ambassador-ecom-v1-checkout/types';
import { LoyaltyAccount } from '@wix/ambassador-loyalty-v1-account/types';
import { LoyaltyCoupon } from '@wix/ambassador-loyalty-v1-coupon/types';
import { LoyaltyEarningRule } from '@wix/ambassador-loyalty-v1-loyalty-earning-rule/types';
import { LoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/types';
import { Reward as LoyaltyReward } from '@wix/ambassador-loyalty-v1-reward/types';
import { CouponNames } from '@wix/loyalty-coupon-names';

import { SelectedRewardType } from './constants';
import { ReferralReward } from './getReferralRewards';

export interface State {
  pendingRequestsCount: number;
  hasFailedToLoad: boolean;
  isLoggedIn: boolean;
  checkout?: Checkout;
  loyaltyProgram?: LoyaltyProgram;
  loyaltyRewards?: LoyaltyReward[];
  loyaltyCoupons?: LoyaltyCoupon[];
  referralRewards?: ReferralReward[];
  loyaltyEarningRules?: LoyaltyEarningRule[];
  loyaltyAccount?: LoyaltyAccount;
  selectedRewardType?: SelectedRewardType;
  showCouponAlreadyAppliedError: boolean;
  showFlexibleRewardSubscriptionError: boolean;
  isFlexibleRewardFormTouched: boolean;
  flexibleRewardPointsValue: string;
  hasFailedToApplyFlexibleReward: boolean;
  hasFailedToApplyFixedReward: boolean;
  selectedFixedRewardId?: string;
  couponNames: CouponNames | undefined;
}

export const defaultState: State = {
  pendingRequestsCount: 1, // Always start rendering in a loading state
  hasFailedToLoad: false,
  isLoggedIn: false,
  checkout: undefined,
  loyaltyProgram: undefined,
  loyaltyRewards: undefined,
  loyaltyCoupons: undefined,
  referralRewards: undefined,
  loyaltyEarningRules: undefined,
  loyaltyAccount: undefined,
  selectedRewardType: undefined,
  showCouponAlreadyAppliedError: false,
  showFlexibleRewardSubscriptionError: false,
  isFlexibleRewardFormTouched: false,
  flexibleRewardPointsValue: '',
  hasFailedToApplyFlexibleReward: false,
  hasFailedToApplyFixedReward: false,
  selectedFixedRewardId: undefined,
  couponNames: undefined,
};
