import { LoyaltyAccount } from '@wix/ambassador-loyalty-v1-account/types';
import { Reward as LoyaltyReward, RewardType } from '@wix/ambassador-loyalty-v1-reward/types';

export interface FlexibleRewardConfig {
  name: string;
  discount: number;
  discountPerPoint: number;
  costInPoints: number;
}

export function getFlexibleRewardConfig(
  flexibleReward?: LoyaltyReward,
  loyaltyAccount?: LoyaltyAccount,
): FlexibleRewardConfig {
  const userTierId = loyaltyAccount?.tier?.id;
  const configsByTier =
    flexibleReward?.type === RewardType.SPI_DISCOUNT_AMOUNT
      ? flexibleReward?.spiDiscountAmount?.configsByTier
      : flexibleReward?.discountAmount?.configsByTier;

  const activeTierConfig = configsByTier?.find(({ tierId }) => userTierId === tierId || (!tierId && !userTierId));
  const discount = activeTierConfig?.amount ? parseFloat(activeTierConfig.amount) : 0;
  const costInPoints = activeTierConfig?.costInPoints ?? 0;
  const discountPerPoint = costInPoints ? discount / costInPoints : 0;
  const name = flexibleReward?.name ?? '';

  return {
    name,
    discount,
    discountPerPoint,
    costInPoints,
  };
}
