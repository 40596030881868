import { createBlocksModel, WidgetPropertyType } from '@wix/yoshi-flow-editor/blocks';

export default createBlocksModel({
  widgetName: 'LoyaltyCheckoutPluginProgress',
  props: {
    label: {
      type: WidgetPropertyType.STRING,
      defaultValue: '',
    },
    progressMaxValue: {
      type: WidgetPropertyType.NUMBER,
      defaultValue: 100,
    },
    progressValue: {
      type: WidgetPropertyType.NUMBER,
      defaultValue: 0,
    },
    progressStatus: {
      type: WidgetPropertyType.STRING,
      defaultValue: '',
    },
  },
  methods: {},
  events: {},
});
