import { listRewards } from '@wix/ambassador-loyalty-v1-reward/http';
import { Reward as LoyaltyReward } from '@wix/ambassador-loyalty-v1-reward/types';
import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { Experiment } from '../../../constants';

export async function getLoyaltyRewards(flowAPI: PlatformControllerFlowAPI): Promise<LoyaltyReward[]> {
  const { httpClient, experiments } = flowAPI;
  const redemptionContext = experiments.enabled(Experiment.FetchCustomRewardsFromSpi);

  const response = await httpClient.request(
    listRewards({
      redemptionContext,
    }),
  );

  return response.data.rewards!;
}
