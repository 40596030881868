import { queryLoyaltyCheckoutDiscounts } from '@wix/ambassador-loyalty-checkoutexchange-v1-loyalty-checkout-discount/http';
import { LoyaltyCheckoutDiscount } from '@wix/ambassador-loyalty-checkoutexchange-v1-loyalty-checkout-discount/types';
import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

export async function getLoyaltyCheckoutDiscounts(
  flowAPI: PlatformControllerFlowAPI,
  checkoutId: string,
): Promise<LoyaltyCheckoutDiscount[]> {
  const { httpClient } = flowAPI;
  const response = await httpClient.request(
    queryLoyaltyCheckoutDiscounts({
      query: {
        filter: { checkoutId },
      },
    }),
  );

  return response.data.loyaltyCheckoutDiscounts!;
}
