import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

export async function promptLogin(flowAPI: PlatformControllerFlowAPI): Promise<boolean> {
  const { wixCodeApi } = flowAPI.controllerConfig;

  try {
    const user = await wixCodeApi.user.promptLogin({
      mode: 'login',
      modal: true,
    });

    return user.loggedIn;
  } catch {}

  return false;
}
