import { createBlocksModel, WidgetPropertyType } from '@wix/yoshi-flow-editor/blocks';

export default createBlocksModel({
  widgetName: 'LoyaltyCheckoutPluginFlexible',
  props: {
    inputLabel: {
      type: WidgetPropertyType.STRING,
      defaultValue: '',
    },
    inputPlaceholder: {
      type: WidgetPropertyType.STRING,
      defaultValue: '',
    },
    inputValue: {
      type: WidgetPropertyType.STRING,
      defaultValue: '',
    },
    errorMessage: {
      type: WidgetPropertyType.STRING,
      defaultValue: '',
    },
    hasFailedToApplyReward: {
      type: WidgetPropertyType.BOOLEAN,
      defaultValue: false,
    },
    statusText: {
      type: WidgetPropertyType.STRING,
      defaultValue: '',
    },
  },
  methods: {},
  events: {
    input: {},
    inputBlur: {},
    inputChange: {},
  },
});
