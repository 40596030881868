import { Checkout, Scope } from '@wix/ambassador-ecom-v1-checkout/types';
import { Coupon } from '@wix/ambassador-ecommerce-coupons-v2-coupon/types';
import { DiscountType as ReferralCouponType } from '@wix/ambassador-loyalty-referral-v1-referral-reward/types';
import { LoyaltyAccount } from '@wix/ambassador-loyalty-v1-account/types';
import { LoyaltyCoupon, Scope as LoyaltyCouponScope } from '@wix/ambassador-loyalty-v1-coupon/types';
import { Reward as LoyaltyReward, RewardType } from '@wix/ambassador-loyalty-v1-reward/types';
import {
  WIX_NEW_STORES as APP_DEFINITION_ID_STORES_NEW,
  WIX_STORES as APP_DEFINITION_ID_STORES_OLD,
} from '@wix/app-definition-ids';
import { CouponNames } from '@wix/loyalty-coupon-names';

import { FixedRewardSource, RewardDiscountType } from './constants';
import { ReferralReward } from './getReferralRewards';

interface BaseFixedReward {
  id: string;
  source: FixedRewardSource;
  revision: string;
  createdTime: number;
  name: string;
  description: string;
}

interface LoyaltyFixedRewardBase extends BaseFixedReward {
  source: FixedRewardSource.LoyaltyReward;
  discountType: RewardDiscountType;
  costInPoints: number;
}

export interface LoyaltyCouponFixedReward extends BaseFixedReward {
  source: FixedRewardSource.LoyaltyCoupon;
  code: string;
}

export interface ReferralFixedReward extends BaseFixedReward {
  source: FixedRewardSource.ReferralReward;
  code: string;
}

interface LoyaltyMoneyFixedReward extends LoyaltyFixedRewardBase {
  discountType: RewardDiscountType.Money;
  moneyAmountDiscount: number;
}

interface LoyaltyPercentageFixedReward extends LoyaltyFixedRewardBase {
  discountType: RewardDiscountType.Percentage;
  percentageDiscount: number;
}

interface LoyaltyFreeShippingFixedReward extends LoyaltyFixedRewardBase {
  discountType: RewardDiscountType.FreeShipping;
}

export type LoyaltyFixedReward =
  | LoyaltyMoneyFixedReward
  | LoyaltyPercentageFixedReward
  | LoyaltyFreeShippingFixedReward;

export type FixedReward = LoyaltyFixedReward | LoyaltyCouponFixedReward | ReferralFixedReward;

interface GetValidFixedRewardsParams {
  checkout: Checkout;
  loyaltyAccount?: LoyaltyAccount;
  activeLoyaltyRewards: LoyaltyReward[];
  activeLoyaltyCoupons: LoyaltyCoupon[];
  activeReferralRewards: ReferralReward[];
  isSubscriptionOrder: boolean;
  couponNames: CouponNames;
}

// Returns a list of all valid fixed rewards for this checkout.
// The user might not have enough points to redeem them, however.
export function getValidFixedRewards({
  checkout,
  loyaltyAccount,
  activeLoyaltyRewards,
  activeLoyaltyCoupons,
  activeReferralRewards,
  isSubscriptionOrder,
  couponNames,
}: GetValidFixedRewardsParams): FixedReward[] {
  const userTierId = loyaltyAccount?.tier?.id ?? undefined;
  const validCouponScopeIds = getValidCouponScopeIds(checkout);
  const orderSubtotal = parseFloat(checkout.priceSummary?.subtotal?.amount ?? '0');

  return [
    ...getFixedRewardsFromLoyaltyRewards({
      userTierId,
      activeLoyaltyRewards,
      couponNames,
      validCouponScopeIds,
      orderSubtotal,
      isSubscriptionOrder,
    }),
    ...getFixedRewardsFromLoyaltyCoupons({
      activeLoyaltyCoupons,
      couponNames,
      validCouponScopeIds,
      orderSubtotal,
      isSubscriptionOrder,
    }),
    ...getFixedRewardsFromReferralRewards({
      activeReferralRewards,
      couponNames,
      validCouponScopeIds,
      orderSubtotal,
      isSubscriptionOrder,
    }),
  ];
}

type GetFixedRewardsFromLoyaltyRewardsParams = Pick<
  GetValidFixedRewardsParams,
  'activeLoyaltyRewards' | 'couponNames' | 'isSubscriptionOrder'
> & {
  userTierId?: string;
  validCouponScopeIds: string[];
  orderSubtotal: number;
};

function getFixedRewardsFromLoyaltyRewards({
  userTierId,
  activeLoyaltyRewards,
  couponNames,
  validCouponScopeIds,
  orderSubtotal,
  isSubscriptionOrder,
}: GetFixedRewardsFromLoyaltyRewardsParams): LoyaltyFixedReward[] {
  const rewards: LoyaltyFixedReward[] = [];

  activeLoyaltyRewards
    .filter(({ type, couponReward }) => {
      if (type !== RewardType.COUPON_REWARD || !couponReward) {
        return false;
      }

      const { scope, minimumSubtotal, appliesToSubscriptions } = couponReward;

      return isValidFixedRewardConfig({
        scope,
        validCouponScopeIds,
        minimumSubtotal,
        orderSubtotal,
        isSubscriptionOrder,
        appliesToSubscriptions,
      });
    })
    .forEach(({ id, revision, name, couponReward, createdDate }) => {
      const baseFixedReward = {
        id: id!,
        source: FixedRewardSource.LoyaltyReward,
        revision: revision!,
        name: name!,
        createdTime: new Date(createdDate!).getTime(),
      } as const;

      if (couponReward?.fixedAmount) {
        const activeTierConfig = couponReward?.fixedAmount?.configsByTier?.find(
          ({ tierId }) => userTierId === tierId || (!tierId && !userTierId),
        );

        if (activeTierConfig) {
          const costInPoints = activeTierConfig.costInPoints!;
          const moneyAmountDiscount = activeTierConfig.amount!;
          const description = couponNames.getCouponSubtitle({
            couponReward: couponReward!,
            config: activeTierConfig,
          });

          if (costInPoints && moneyAmountDiscount) {
            rewards.push({
              ...baseFixedReward,
              description,
              costInPoints,
              discountType: RewardDiscountType.Money,
              moneyAmountDiscount,
            });
          }
        }
      } else if (couponReward?.percentage) {
        const activeTierConfig = couponReward?.percentage?.configsByTier?.find(
          ({ tierId }) => userTierId === tierId || (!tierId && !userTierId),
        );

        if (activeTierConfig) {
          const costInPoints = activeTierConfig.costInPoints;
          const percentageDiscount = activeTierConfig.percentage;
          const description = couponNames.getCouponSubtitle({
            couponReward: couponReward!,
            config: activeTierConfig,
          });

          if (costInPoints && percentageDiscount) {
            rewards.push({
              ...baseFixedReward,
              description,
              costInPoints,
              discountType: RewardDiscountType.Percentage,
              percentageDiscount,
            });
          }
        }
      } else if (couponReward?.freeShipping) {
        const activeTierConfig = couponReward?.freeShipping?.configsByTier?.find(
          ({ tierId }) => userTierId === tierId || (!tierId && !userTierId),
        );

        if (activeTierConfig) {
          const costInPoints = activeTierConfig.costInPoints;
          const description = couponNames.getCouponSubtitle({
            couponReward: couponReward!,
            config: activeTierConfig,
          });

          if (costInPoints) {
            rewards.push({
              ...baseFixedReward,
              description,
              costInPoints,
              discountType: RewardDiscountType.FreeShipping,
            });
          }
        }
      }
    });

  return rewards;
}

type GetFixedRewardsFromLoyaltyCouponsParams = Pick<
  GetValidFixedRewardsParams,
  'activeLoyaltyCoupons' | 'couponNames' | 'isSubscriptionOrder'
> & {
  validCouponScopeIds: string[];
  orderSubtotal: number;
};

function getFixedRewardsFromLoyaltyCoupons({
  activeLoyaltyCoupons,
  couponNames,
  validCouponScopeIds,
  orderSubtotal,
  isSubscriptionOrder,
}: GetFixedRewardsFromLoyaltyCouponsParams): LoyaltyCouponFixedReward[] {
  return activeLoyaltyCoupons
    .filter(({ couponReference }) => {
      const specification = couponReference?.specification;
      if (!specification) {
        return false;
      }

      const { minimumSubtotal, appliesToSubscriptions } = specification;
      const scope = specification.scope ? convertFromLoyaltyCouponScope(specification.scope) : undefined;

      return isValidFixedRewardConfig({
        scope,
        validCouponScopeIds,
        minimumSubtotal,
        orderSubtotal,
        isSubscriptionOrder,
        appliesToSubscriptions,
      });
    })
    .map(({ id, revision, couponReference, createdDate }) => ({
      id: id!,
      source: FixedRewardSource.LoyaltyCoupon,
      revision: revision!,
      name: couponReference?.name!,
      code: couponReference?.code!,
      createdTime: new Date(createdDate!).getTime(),
      description: couponNames.getCouponSubtitle({
        coupon: couponReference!,
        referenceCoupon: true,
      }),
    }));
}

type GetFixedRewardsFromReferralRewardsParams = Pick<
  GetValidFixedRewardsParams,
  'activeReferralRewards' | 'couponNames' | 'isSubscriptionOrder'
> & {
  validCouponScopeIds: string[];
  orderSubtotal: number;
};

function getFixedRewardsFromReferralRewards({
  activeReferralRewards,
  couponNames,
  validCouponScopeIds,
  orderSubtotal,
  isSubscriptionOrder,
}: GetFixedRewardsFromReferralRewardsParams): ReferralFixedReward[] {
  return activeReferralRewards
    .filter(({ coupon }) => {
      const specification = coupon?.couponSpecification;
      if (!specification) {
        return false;
      }

      const { scope, minimumSubtotal, appliesToSubscriptions } = specification;

      return isValidFixedRewardConfig({
        scope,
        validCouponScopeIds,
        minimumSubtotal,
        orderSubtotal,
        isSubscriptionOrder,
        appliesToSubscriptions,
      });
    })
    .map(({ id, revision, coupon, createdDate, entityName }) => {
      const { code, couponSpecification } = coupon!;
      const couponType = couponSpecification?.discountType;
      const fakeCoupon: Coupon = {
        ...(entityName && {
          displayData: { name: entityName },
        }),
        specification: {
          scope: couponSpecification?.scope,
          minimumSubtotal: couponSpecification?.minimumSubtotal,
          moneyOffAmount:
            couponType === ReferralCouponType.FIXED_AMOUNT
              ? couponSpecification?.fixedAmountOptions?.amount
              : undefined,
          percentOffRate:
            couponType === ReferralCouponType.PERCENTAGE
              ? couponSpecification?.percentageOptions?.percentage
              : undefined,
          freeShipping: couponType === ReferralCouponType.FREE_SHIPPING,
          code,
          limitedToOneItem: couponSpecification?.limitedToOneItem,
          appliesToSubscriptions: couponSpecification?.appliesToSubscriptions,
          discountedCycleCount: couponSpecification?.discountedCycleCount,
        },
      };

      return {
        id: id!,
        source: FixedRewardSource.ReferralReward,
        revision: revision!,
        name: couponSpecification?.name!,
        code: code!,
        createdTime: new Date(createdDate!).getTime(),
        description: couponNames.getCouponSubtitle({
          coupon: fakeCoupon,
        }),
      };
    });
}

function getCouponScopeId({ namespace, group }: Scope): string {
  const scopeParts = [];

  if (namespace) {
    scopeParts.push(namespace);
    if (group?.name) {
      scopeParts.push(group.name);
      if (group.entityId) {
        scopeParts.push(group.entityId);
      }
    }
  }

  return scopeParts.join('.');
}

function getValidCouponScopeIds(checkout: Checkout): string[] {
  const allValidCouponScopes = checkout.lineItems?.map(({ couponScopes }) => couponScopes ?? []).flat() ?? [];
  const allValidCouponScopeIds = allValidCouponScopes.map((scope) => getCouponScopeId(scope));

  // For some reason stores does not include base `stores` namespace as valid couponScope (other verticals
  // like restaurants do include it). So just manually add this scope as valid.
  const hasStoresProducts =
    checkout.lineItems?.some(({ catalogReference }) =>
      [APP_DEFINITION_ID_STORES_OLD, APP_DEFINITION_ID_STORES_NEW].includes(catalogReference?.appId ?? ''),
    ) ?? false;

  if (hasStoresProducts) {
    allValidCouponScopeIds.push('stores');
  }

  return [...new Set(allValidCouponScopeIds)];
}

interface IsValidFixedRewardParams {
  scope?: Scope;
  validCouponScopeIds: string[];
  minimumSubtotal: number | null | undefined;
  orderSubtotal: number;
  isSubscriptionOrder: boolean;
  appliesToSubscriptions: boolean | null | undefined;
}

function isValidFixedRewardConfig({
  scope,
  validCouponScopeIds,
  minimumSubtotal,
  orderSubtotal,
  isSubscriptionOrder,
  appliesToSubscriptions,
}: IsValidFixedRewardParams) {
  if (scope) {
    const couponScopeId = getCouponScopeId(scope);
    if (!validCouponScopeIds.includes(couponScopeId)) {
      return false;
    }
  }

  if (minimumSubtotal && orderSubtotal < minimumSubtotal) {
    return false;
  }

  if (isSubscriptionOrder && !appliesToSubscriptions) {
    return false;
  }

  return true;
}

function convertFromLoyaltyCouponScope({ namespace, name, entityId }: LoyaltyCouponScope): Scope {
  const scope: Scope = { namespace };

  if (name) {
    scope.group = { name, entityId };
  }

  return scope;
}
