import {
  DiscountStatus,
  DiscountType,
} from '@wix/ambassador-loyalty-checkoutexchange-v1-loyalty-checkout-discount/types';
import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { getLoyaltyCheckoutDiscounts } from './getLoyaltyCheckoutDiscounts';

interface WaitForRefundParams {
  flowAPI: PlatformControllerFlowAPI;
  checkoutId: string;
  retries: number;
}

const MIN_RETRY_WAIT_TIME = 1000;
const MAX_RETRY = 4;

const wait = (milliseconds: number) => new Promise((resolve) => setTimeout(resolve, milliseconds));

const waitForRefund = async ({ flowAPI, checkoutId, retries = 1 }: WaitForRefundParams): Promise<void> => {
  const discounts = await getLoyaltyCheckoutDiscounts(flowAPI, checkoutId);
  const hasPendingDiscounts = discounts.some(
    ({ status, discountType }) =>
      status === DiscountStatus.IN_PROGRESS && discountType === DiscountType.DISCOUNT_AMOUNT,
  );

  if (hasPendingDiscounts && retries <= MAX_RETRY) {
    await wait(retries * MIN_RETRY_WAIT_TIME);
    return waitForRefund({ flowAPI, checkoutId, retries: retries + 1 });
  }
};

export const waitForFlexibleRewardRefund = (flowAPI: PlatformControllerFlowAPI, checkoutId: string) =>
  waitForRefund({ flowAPI, checkoutId, retries: 1 });
