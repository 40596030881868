import { createBlocksModel, WidgetPropertyType } from '@wix/yoshi-flow-editor/blocks';

export default createBlocksModel({
  widgetName: 'LoyaltyCheckoutPluginFixed',
  props: {
    dropdownLabel: {
      type: WidgetPropertyType.STRING,
      defaultValue: '',
    },
    dropdownOptions: {
      // NOTE: This is an array of objects (Blocks does not allow to type it correctly yet)
      type: WidgetPropertyType.STRING,
    },
    dropdownValue: {
      type: WidgetPropertyType.STRING,
    },
    errorMessage: {
      type: WidgetPropertyType.STRING,
      defaultValue: '',
    },
    statusText: {
      type: WidgetPropertyType.STRING,
      defaultValue: '',
    },
  },
  methods: {},
  events: {
    dropdownChange: {},
  },
});
