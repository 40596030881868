import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

const REFERRALS_APP_DEFINITION_ID = '58be0514-fc10-4eb1-886e-2909d18f33b3';
const REFERRAL_PAGE_ID = 'wix-referrals-referral';

export async function isReferralsAppInstalled(flowAPI: PlatformControllerFlowAPI): Promise<boolean> {
  const { wixCodeApi } = flowAPI.controllerConfig;

  try {
    const isReferralPageInstalled = await wixCodeApi.site.isAppSectionInstalled({
      appDefinitionId: REFERRALS_APP_DEFINITION_ID,
      sectionId: REFERRAL_PAGE_ID,
    });

    return isReferralPageInstalled;
  } catch {
    return false;
  }
}
