import { queryReferralRewards } from '@wix/ambassador-loyalty-referral-v1-referral-reward/http';
import { ReferralReward as ReferralRewardBase } from '@wix/ambassador-loyalty-referral-v1-referral-reward/types';
import { getCouponEntityName } from '@wix/referrals-reward-descriptions';
import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

export interface ReferralReward extends ReferralRewardBase {
  entityName?: string;
}

export async function getReferralRewards(flowAPI: PlatformControllerFlowAPI): Promise<ReferralReward[]> {
  const { httpClient } = flowAPI;
  let referralRewards: ReferralReward[] = [];

  // This method fails when user is not a referred friend or referring customer
  try {
    const response = await httpClient.request(
      queryReferralRewards({
        contactId: 'me',
        query: {
          filter: {
            rewardType: 'CouponReward',
          },
        },
      }),
    );

    referralRewards = response.data.referralRewards!;
  } catch {}

  const entityNamePromises = referralRewards.map(({ coupon }) => {
    const couponScope = coupon?.couponSpecification?.scope;
    return couponScope ? getCouponEntityName({ httpClient, couponScope }) : Promise.resolve(null);
  });

  try {
    const entityNameResult = await Promise.all(entityNamePromises);
    entityNameResult.forEach((entityName, index) => {
      if (entityName) {
        referralRewards[index].entityName = entityName;
      }
    });
  } catch {}

  return referralRewards;
}
