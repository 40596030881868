import { Checkout } from '@wix/ambassador-ecom-v1-checkout/types';

import { calculateMaxOrderDiscount } from './calculateMaxOrderDiscount';
import { FlexibleRewardConfig } from './getFlexibleRewardConfig';

interface Params {
  checkout?: Checkout;
  userPointsAmount: number;
  flexibleRewardConfig: FlexibleRewardConfig;
}

export function calculateMaxFlexibleRewardPoints({ checkout, userPointsAmount, flexibleRewardConfig }: Params): number {
  const { costInPoints, discountPerPoint } = flexibleRewardConfig;
  const maxOrderDiscount = calculateMaxOrderDiscount(checkout);
  const maxPointsForOrder = discountPerPoint ? Math.ceil(maxOrderDiscount / discountPerPoint) : 0;

  return Math.min(userPointsAmount, Math.max(maxPointsForOrder, costInPoints));
}
