import { Checkout, LineItem } from '@wix/ambassador-ecom-v1-checkout/types';
import { LoyaltyAccount } from '@wix/ambassador-loyalty-v1-account/types';
import { LoyaltyEarningRule } from '@wix/ambassador-loyalty-v1-loyalty-earning-rule/types';
import {
  WIX_BOOKINGS as APP_DEFINITION_ID_BOOKINGS,
  WIX_NEW_STORES as APP_DEFINITION_ID_STORES_NEW,
  WIX_RESTAURANTS_ORDERS_NEW as APP_DEFINITION_ID_RESTAURANTS,
  WIX_STORES as APP_DEFINITION_ID_STORES_OLD,
} from '@wix/app-definition-ids';

const storesAppDefIds = [APP_DEFINITION_ID_STORES_OLD, APP_DEFINITION_ID_STORES_NEW];

interface EarningRuleConfig {
  appIds: string[];
  shouldGivePointsForEachItem?: boolean;
}

const earningRuleConfigByTriggerActivityType: Record<string, EarningRuleConfig> = {
  'stores/OrderPaid': {
    appIds: storesAppDefIds,
  },
  'restaurants-order-is-pending': {
    appIds: [APP_DEFINITION_ID_RESTAURANTS],
  },
  'bookings/BookingConfirmed': {
    appIds: [APP_DEFINITION_ID_BOOKINGS],
    shouldGivePointsForEachItem: true,
  },
};

const convertMoneyToNumber = (money: string | undefined): number => parseFloat(money ?? '0');

const getOrderAmountForApp = (appId: string, checkout: Checkout): number => {
  if (storesAppDefIds.includes(appId)) {
    return getOrderAmountForStoresApp(checkout);
  } else if (appId === APP_DEFINITION_ID_RESTAURANTS) {
    return getOrderAmountForRestaurantsApp(checkout);
  }

  return (
    checkout.lineItems?.reduce((orderAmount, lineItem) => {
      if (appId === lineItem.catalogReference?.appId) {
        const lineItemPrice = convertMoneyToNumber(lineItem.lineItemPrice?.amount);
        return orderAmount + lineItemPrice;
      }

      return orderAmount;
    }, 0) ?? 0
  );
};

// Stores uses different calculations and we have to match backend, see:
// https://github.com/wix-private/app-market/blob/148797cfcff13b190d959ceef3fadcecf3e71277/loyalty/loyalty-triggers/src/com/wixpress/loyalty/trigger/stores/StoresOrderPriceCalculator.scala#L6
const getOrderAmountForStoresApp = (checkout: Checkout): number => {
  const allLineItems = checkout.lineItems ?? [];
  const storesLineItems = allLineItems.filter(
    ({ catalogReference }) => !!catalogReference?.appId && storesAppDefIds.includes(catalogReference.appId),
  );

  const getTotalItemsPriceWithDiscount = (lineItems: LineItem[]): number =>
    lineItems.reduce(
      (totalPrice, lineItem) => totalPrice + convertMoneyToNumber(lineItem.totalPriceAfterTax?.amount),
      0,
    );

  const getTotalItemsDiscount = (lineItems: LineItem[]): number =>
    lineItems.reduce((totalDiscount, lineItem) => totalDiscount + convertMoneyToNumber(lineItem.discount?.amount), 0);

  const getTotalItemsPriceWithoutDiscount = (lineItems: LineItem[]): number =>
    getTotalItemsPriceWithDiscount(lineItems) + getTotalItemsDiscount(lineItems);

  const totalPrice = convertMoneyToNumber(checkout.priceSummary?.total?.amount);
  const totalDiscount = convertMoneyToNumber(checkout.priceSummary?.discount?.amount);
  const totalPriceOfStoreItems = getTotalItemsPriceWithoutDiscount(storesLineItems);
  const totalPriceOfAllItems = getTotalItemsPriceWithoutDiscount(allLineItems);

  if (totalPriceOfAllItems <= 0 || totalPriceOfStoreItems <= 0) {
    return 0;
  }

  const storesPartOfTotalOrder = totalPriceOfStoreItems / totalPriceOfAllItems;
  const globalDiscount = totalDiscount - getTotalItemsDiscount(allLineItems);
  const storesGlobalDiscountPart = globalDiscount * storesPartOfTotalOrder;
  const priceOfStoreItems = getTotalItemsPriceWithDiscount(storesLineItems);

  if (priceOfStoreItems === totalPrice) {
    return priceOfStoreItems;
  } else {
    return priceOfStoreItems - storesGlobalDiscountPart;
  }
};

const getOrderAmountForRestaurantsApp = (checkout: Checkout): number => {
  // To match backend - we are using order total. Should be fine since restaurant line items cannot be ordered
  // together with other vertical line items (in the same cart).
  return convertMoneyToNumber(checkout.priceSummary?.total?.amount);
};

export function calculateEarnPointsAmount(
  activeEarningRules: LoyaltyEarningRule[],
  checkout: Checkout,
  loyaltyAccount?: LoyaltyAccount,
): number {
  const userTierId = loyaltyAccount?.tier?.id;

  return activeEarningRules.reduce((earnPointsAmount, earningRule) => {
    const { triggerAppId, triggerActivityType } = earningRule;
    if (!triggerAppId || !triggerActivityType) {
      return earnPointsAmount;
    }

    const earningRuleConfig = earningRuleConfigByTriggerActivityType[triggerActivityType];
    if (!earningRuleConfig || !earningRuleConfig.appIds.includes(triggerAppId)) {
      return earnPointsAmount;
    }

    const hasRelevantLineItems = checkout.lineItems?.some(
      ({ catalogReference }) => !!catalogReference?.appId && earningRuleConfig.appIds.includes(catalogReference.appId),
    );

    if (!hasRelevantLineItems) {
      return earnPointsAmount;
    }

    const { conversionRate, fixedAmount } = earningRule;

    if (fixedAmount) {
      const activeTierConfig = fixedAmount.configs?.find(
        ({ tierId }) => userTierId === tierId || (!tierId && !userTierId),
      );
      let ruleEarnPointsAmount = activeTierConfig?.points ?? 0;
      const { shouldGivePointsForEachItem = false } = earningRuleConfig;

      if (shouldGivePointsForEachItem) {
        const itemsCount =
          checkout.lineItems?.reduce((count, { catalogReference, quantity }) => {
            if (catalogReference?.appId === triggerAppId) {
              return count + (quantity ?? 0);
            }

            return count;
          }, 0) ?? 0;

        ruleEarnPointsAmount *= itemsCount;
      }

      return earnPointsAmount + ruleEarnPointsAmount;
    } else if (conversionRate) {
      const activeTierConfig = conversionRate.configs?.find(
        ({ tierId }) => userTierId === tierId || (!tierId && !userTierId),
      );
      const moneyAmount = activeTierConfig?.moneyAmount ?? 0;
      const points = activeTierConfig?.points ?? 0;
      let ruleEarnPointsAmount = 0;

      if (moneyAmount && points) {
        const orderAmount = getOrderAmountForApp(triggerAppId, checkout);
        ruleEarnPointsAmount = Math.floor(orderAmount / moneyAmount) * points;
      }

      return earnPointsAmount + ruleEarnPointsAmount;
    }

    return earnPointsAmount;
  }, 0);
}
